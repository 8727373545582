import { createSlice } from '@reduxjs/toolkit';
import { getPageMetaDataByStoreApi, getPageSectionBySectionKeyApi, getStoreCategoriesApi } from './commonApi';

const commonSlice = createSlice({
	name: 'commonSlice',
	initialState: {
		showAuthModal: '',
		alertMsg: {},
		showBackdrop: false,
		pageMetaData: {},
		pageSectionData: {},
		categories: []
	},
	reducers: {
		setAuthModal: (state, action) => {
			state.showAuthModal = action.payload;
		},
		setAlertMsg: (state, action) => {
			state.alertMsg = action.payload;
		},
		setBackdrop: (state, action) => {
			state.showBackdrop = action.payload;
		},
		setPageSectionData: (state, action) => {
			state.pageSectionData = action.payload;
		}
	},
	extraReducers: {
		[getPageMetaDataByStoreApi.fulfilled]: (state, action) => {
			state.pageMetaData = action.payload;
		},
		[getStoreCategoriesApi.fulfilled]: (state, action) => {
			state.categories = action.payload;
		}

		//  [getPageSectionBySectionKeyApi.fulfilled]: (state,action) => {
		// 	const updatedPageSectionData = {...state.pageSectionData};
		// 	updatedPageSectionData[action.payload.sectionKey] = action.payload.data;
		// 	state.pageSectionData = updatedPageSectionData;
		//  }
	}
});

export const { setAuthModal, setAlertMsg, setBackdrop, setPageSectionData } = commonSlice.actions;

export default commonSlice.reducer;
