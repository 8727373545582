import axios from 'axios';

const instance = axios.create({
	// baseURI: config.baseURI,
	timeout: 1000 * 10,
	headers: { 'Content-Type': 'application/json' }
});

/**
 * Request interceptor
 * Add Authorization header if it exists
 * This configuration applies for all requests
 */
instance.interceptors.request.use(
	reqConfig => {
		const access_token = localStorage.getItem('token');

		if (!reqConfig.headers) {
			reqConfig.headers = {};
		}

		reqConfig.headers.Authorization = access_token ? `Bearer ${access_token || ''}` : '';
		reqConfig.timeout = 150000;

		return reqConfig;
	},
	error => {
		return Promise.reject(error);
	}
);

/**
 * Response interceptor
 * Catch basic errors like 401 and redirect to login
 * This configuration applies for all responses
 */
instance.interceptors.response.use(
	response => response,
	error => {
		// Do something with response error
		if (typeof error === 'undefined') {
			// request cancelled
			// when backend server is not available at all
		} else if (typeof error.response === 'undefined') {
			// when request is timeout
		} else if (error.response.status === 401) {
			// apply refresh token logic here instead of redirecting to login
			// localStorage.clear()
			// localStorage.clear()
			// store.dispatch(push('/login'))
		}

		return Promise.reject(error.response);
	}
);

export default instance;
