import { configureStore } from '@reduxjs/toolkit';
import * as history from 'history';
import reducer from './rootReducer';

const store = configureStore({
	reducer
});
export const appHistory = history.createBrowserHistory();

export default store;
