export const desiredRole = {
	ROLE_USER: 'ROLE_USER',
	ROLE_ADMIN: 'ROLE_ADMIN',
	ROLE_STORE_EMPLOYEE: 'ROLE_STORE_EMPLOYEE',
	ROLE_MERCHANT: 'ROLE_MERCHANT',
	ROLE_STORE_MANAGER: 'ROLE_STORE_MANAGER',
	ROLE_STORE_DELIVERY_BOY: 'ROLE_STORE_DELIVERY_BOY',
	ROLE_MARKETING_PARTNER: 'ROLE_MARKETING_PARTNER',
	ROLE_PARTNER: 'ROLE_PARTNER',
	ROLE_PLATFORM_OWNER: 'ROLE_PLATFORM_OWNER'
};

export const ABC = 'ABC';
