import axios from 'axios';

const API_URL =window?.env?.API_URL;

export const searchProduct = '/store/web/searchProduct';

export function findProducts(queryParams) {
	console.log('searchProduct', searchProduct);
	return axios.post(`${API_URL}${searchProduct}`, queryParams);
}
