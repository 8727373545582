/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { getStoreCategoriesApi } from 'store/common/commonApi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { registerUserForNewsLetterApi } from 'store/common/authApi';
import { isArray } from 'lodash';
import styles from './Footer.module.scss';
import * as Yup from 'yup';

import { useFormik } from 'formik';
const initialValues = {
	email: ''
};

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Please Enter Valid Email Id').required('Please Enter Valid Email Id')
});

const importantLinks = [
	{
		name: 'About Janavi',
		path: 'about-us'
	},
	{
		name: 'Product Care',
		path: 'product-care'
	},
	{
		name: 'Terms & Conditions',
		path: 'terms-conditions'
	},
	{
		name: 'Shipping & Returns',
		path: 'shipping-returns'
	},
	{
		name: 'Privacy Policy',
		path: 'privacy-policy'
	},
	{
		name: 'FAQ',
		path: 'faq'
	},
	{
		name: 'Store Locator',
		path: ''
	}
];

export default function Footer() {
	const dispatch = useDispatch();
	const removeDups = (arr) => {

		if(arr?.length){
			return arr.filter((thing, index) => {
				const _thing = JSON.stringify(thing);
				return index == arr.findIndex(obj => {
					return JSON.stringify(obj) === _thing;
				});
			});
		}else{
			return [];
		}
		
	}
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, formikProps) => {
			formikProps.setSubmitting(true);
			dispatch(registerUserForNewsLetterApi(values)).then(data => {
				formikProps.resetForm();
			});
		}
	});
	useEffect(() => {
		dispatch(getStoreCategoriesApi());
	}, []);
	const { categories = [] } = useSelector(state => state.common);

	return (
		<div>
			<div className="container-fluid position-relative bg-dark">
				{/* <div className={styles.leftFlower}>
					<img src="https://janaviindia.com/static/frontend/Digital/default/en_US/images/footer-left-flower.png" />
				</div>
				<div className={styles.rightFlower}>
					<img src="https://janaviindia.com/static/frontend/Digital/default/en_US/images/right-flower.png" />
				</div> */}
				<div className="col">
					<div className="row align-items-">
						<div className={styles.logoArea}>
							<img
								className={styles.logoImg}
								draggable="false"
								src="https://janaviindia.com/static/frontend/Digital/default/en_US/images/logo.png"
							/>
						</div>
					</div>
					<div className="row d-flex justify-content-center">
						<div className={clsx('col-lg-2 col-md-3 col-sm-12', styles.zIndex)}>
							<div className={styles.menuItems}>
								<p className={styles.navigateHeaderText}>Navigate</p>
								<Link
									to={`/product-list?type=category&referenceId=${'NewArrival'}`}
									className={clsx(styles.linkItem)}
								>
									<span className={clsx(styles.navigateHeaderMessage, styles.onHover)}>
										What's New
									</span>
								</Link>
								{removeDups(categories).map(cat => (
									<Link
										to={`/product-list?type=category&referenceId=${cat?.categoryId}`}
										className={clsx(styles.linkItem)}
									>
										<span className={clsx(styles.navigateHeaderMessage, styles.onHover)}>
											{cat?.categoryName}
										</span>
									</Link>
								))}
							</div>
						</div>
						<div className={clsx('col-lg-2 col-md-3 col-sm-12', styles.zIndex)}>
							<div className={styles.menuItems}>
								<p className={styles.navigateHeaderText}>The Company</p>
								{importantLinks.map(item => (
									<Link to={`/${item.path}`} className={clsx(styles.linkItem)}>
										<span className={clsx(styles.navigateHeaderMessage, styles.onHover)}>
											{item.name}
										</span>
									</Link>
								))}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 d-flex  flex-column">
							<p className={styles.navigateHeaderText}>customer Care</p>
							<span className={styles.spanTexts}>Monday - Saturday</span>
							<span className={styles.spanTexts}>10:00 AM - 6:00 PM (IST)</span>
							<a
								href="tel:917669922289"
								className={clsx(styles.contactNum, styles.onHover)}
								target="_blank"
							>
								+91 7669922289
							</a>
							<a
								href="tel:917669922289"
								className={clsx(styles.contactNum, styles.onHover)}
								target="_blank"
							>
								customerservice@janaviindia.com
							</a>

							{/* <p className={styles.navigateHeaderText}>Visit Us</p>
							<span className={styles.spanTexts}>Store Location </span>
							<span className={styles.spanTexts}>Monday - Sunday</span>
							<span className={styles.spanTexts}>11:00 AM - 8:00 PM (IST)</span> */}
							<div className={styles.newsLetterContainer}>
								<p className={clsx(styles.navigateHeaderText, 'm-0')}>Subscribe</p>
								<form
									className={clsx(styles.newsLetterForm, 'form subscribe')}
									onSubmit={formik.handleSubmit}
								>
									<div className={styles.field}>
										<div className={styles.control}>
											<label className="w-100">
												<input
													name="email"
													type="email"
													id="newsletter"
													placeholder="Email Address"
													className={styles.newsLetterInput}
													{...formik.getFieldProps('email')}
												/>
												{formik.touched.email && formik.errors.email && (
													<div className={styles.errorMsg}>{formik.errors.email}</div>
												)}
											</label>
										</div>
									</div>
									<div className="actions">
										<button
											className={clsx(styles.subscribeBtn, 'action subscribe primary')}
											title="Submit"
											type="submit"
											aria-label="Subscribe"
											id="button_footer_subscribe"
											style={{ margin: '10px ​0 0' }}
										>
											<i className="bi bi-arrow-right-square-fill"></i>
										</button>
									</div>
									<div className={styles.response} />
								</form>
							</div>
						</div>
						<div className={clsx('col-lg-3 col-md-3 col-sm-12 d-flex  flex-column', styles.zIndex)}>
							<div className="row d-flex justify-content-center">
								<div
									className={clsx(
										'col-lg-4 d-flex flex-column  justify-content-start align-item-center',
										styles.zIndex
									)}
								>
									<p className={styles.navigateHeaderText}>social</p>
									{/* <Link className={clsx(styles.website, styles.onHover)} to="/#">
										Facebook
									</Link> */}
									<a className={clsx(styles.website, styles.onHover)} href="https://www.instagram.com/janaviindia/" target="_blank">
										Instagram
									</a>
									{/* <Link className={clsx(styles.website, styles.onHover)} to="/#">
										YouTube
									</Link> */}
								</div>
							</div>
							<div className={clsx(styles.newsLetterContainer, 'd-none')}>
								<p className={clsx(styles.navigateHeaderText, 'm-0')}>Subscribe</p>
								<form className={clsx(styles.newsLetterForm, 'form subscribe')}>
									<div className={styles.field}>
										<div className={styles.control}>
											<label className="w-100">
												<input
													name="email"
													type="email"
													id="newsletter"
													placeholder="Email Address"
													className={styles.newsLetterInput}
													// {...formik.getFieldProps('email')}
												/>
												{/* {formik.touched.email && formik.errors.email && (
													<div className={styles.errorMsg}>{formik.errors.email}</div>
												)} */}
											</label>
										</div>
									</div>
									<div className="actions">
										<button
											className={clsx(styles.subscribeBtn, 'action subscribe primary')}
											title="Submit"
											type="submit"
											aria-label="Subscribe"
											id="button_footer_subscribe"
											style={{ margin: '10px ​0 0' }}
										>
											<i className="bi bi-arrow-right-square-fill"></i>
										</button>
									</div>
									<div className={styles.response} />
								</form>
							</div>
						</div>
					</div>
					<div className={clsx(styles.desktopView)}>
						<div className={clsx(styles.socialLinkBox)}>
							<div className={styles.alrightBox}>
								<div>
									<span className={styles.allRightsText}>&copy; All rights reserved - 2020</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
