const models = {
	cartItem: {
		cartId: '',
		dealId: '',
		discountCode: '',
		offer: false,
		productId: '',
		quantity: ''
	},
	product: {
		categoryId: '',
		filter: '',
		searchString: '',
		pageNo: '',
		pageSize: '',
		sortOption: '',
		sortOrder: ''
	},
	webpageReqDto: {
		displayPage: 'Homepage'
	},
	resetPasswordDto: {
		password: '',
		phoneNumber: ''
	},
	addressDto: {
		addLine1: '',
		addLine2: '',
		addState: '',
		addressType: 'STORE',
		cartId: '',
		city: '',
		country: '',
		lattitude: 0,
		longitude: 0,
		zipCode: ''
	}
};

export default models;
