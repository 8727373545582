import axios from 'axios';

import { createRequestObj, createRequestObjSignup, createProfileRequestObj, createAuthRequestObj, createShoppingRequestObj, createPasswordResetRequestObj } from 'utils/fingerPrint';

const API_URL = window?.env?.API_URL;

export const auth_login = '/auth/login';
export const registerUser = '/auth/register';
export const pageMetaData = '/store/web/getPageMetaDataByStore';
export const getHomepage = '/store/web/getHomepage';
export const getSectionBySectionKey = '/store/web/getPageSectionBySectionKey';
export const lostPassword = '/auth/passwordReset';
const regUserForNewsLet = '/auth/registerUserForNewsLetter';
const store_categories = '/store/web/getStoreCategories';
const view_profile = '/auth/viewProfile';
const update_profile = '/auth/updateUserProfile';
const send_otp = '/auth/sendOtp';
const verify_otp = '/auth/verifyOtp';
const password_reset = '/auth/passwordReset';

export function login(params) {
	return axios.post(`${API_URL}${auth_login}`, createAuthRequestObj(params));
}

export function register(params) {
	console.log('register user', params);
	return axios.post(`${API_URL}${registerUser}`, createRequestObjSignup(params));
}
export function viewProfile(params) {
	console.log('view profile', params);
	return axios.post(`${API_URL}${view_profile}`, createProfileRequestObj(params));
}
export function updateUserProfile(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${update_profile}`,
		data: createRequestObj(params)
	});
}

export function registerUserForNewsLetter(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${regUserForNewsLet}`,
		params
	});
}

export function getPageMetaDataByStore(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${pageMetaData}`,
		data: createRequestObj(params)
	});
}

export function getHomepageApi(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${getHomepage}`,
		data: createRequestObj(params)
	});
}

export function getPageSectionBySectionKey(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${getSectionBySectionKey}`,
		data: createRequestObj(params)
	});
}

export function resetYourPassword(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${lostPassword}`,
		data: createRequestObj(params)
	});
}
export function sendOtp(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${send_otp}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createShoppingRequestObj(params)
	});
}

export function verifyOtp(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${verify_otp}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createShoppingRequestObj(params)
	});
}
export function passwordReset(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${password_reset}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: createPasswordResetRequestObj(params)
	});
}


export function getStoreCategory(params) {
	return axios.request({
		method: 'post',
		url: `${API_URL}${store_categories}`,
		data: createRequestObj(params)
	});
}
