const { ClientJS } = require('clientjs');

const client = new ClientJS();

const fingerPrint = client.getFingerprint();

export function createAuthRequestObj(reqObj) {
	console.log('device fingerprint is', fingerPrint);
	const reqBody = { ...reqObj };

	reqBody.storeId = window.env.STORE_ID;
	return reqBody;
}

export function createShoppingRequestObj(reqObj) {
	console.log('device fingerprint is', fingerPrint);
	const reqBody = { ...reqObj };
	reqBody.storeId = window.env.STORE_ID;
	// reqBody.token = {
	// 	fingerprint: {
	// 		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || '',
	// 		deviceFingerprint: fingerPrint.toString()
	// 	},
	// 	loginToken: localStorage.getItem('loginId') || ''
	// };
	reqBody.deviceFingerprint = {
		deviceFingerprint: fingerPrint.toString(),
		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || ''
	};
	reqBody.token = localStorage.getItem('loginId') || '';
	return reqBody;
}

export function createPasswordResetRequestObj(reqObj) {
	console.log('device fingerprint is', fingerPrint);
	const reqBody = { ...reqObj };
	reqBody.storeId = window.env.STORE_ID;
	reqBody.deviceFingerprint = {
		deviceFingerprint: fingerPrint.toString(),
		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || ''
	};
	return reqBody;
}

export function createProfileRequestObj(reqObj) {
	console.log('device fingerprint is', fingerPrint);
	const reqBody = { ...reqObj };
	reqBody.fingerprint = {
		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || '',
		deviceFingerprint: fingerPrint.toString()
	};
	reqBody.loginToken = localStorage.getItem('loginId') || ''
	return reqBody;
}
export function createRequestObj(reqObj) {
	console.log('device fingerprint is', fingerPrint);
	const reqBody = { ...reqObj };
	reqBody.storeId = window.env.STORE_ID;
	reqBody.token = {
		fingerprint: {
			jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || '',
			deviceFingerprint: fingerPrint.toString()
		},
		loginToken: localStorage.getItem('loginId') || ''
	};
	return reqBody;
}

export function createRequest01Obj(reqObj) {
	console.log('device fingerprint is', fingerPrint);
	const reqBody = { ...reqObj };
	reqBody.storeId = window.env.STORE_ID;
	reqBody.token = {
		fingerprint: {
			createdAt: 0,
			user_id: 0,
			jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || '',
			deviceFingerprint: fingerPrint.toString()
		},
		loginToken: localStorage.getItem('loginId') || ''
	};
	return reqBody;
}
export function createRequestObjSignup(reqObj) {
	const reqBody = { ...reqObj };
	reqBody.deviceFingerprint = {
		deviceFingerprint: fingerPrint.toString(),
		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || ''
	};
	reqBody.storeId = window.env.STORE_ID;
	return reqBody;
}
export function createRequestOrder(reqObj) {
	const reqBody = { ...reqObj };
	reqBody.deviceFingerprint = {
		deviceFingerprint: fingerPrint.toString(),
		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || ''
	};
	reqBody.loginToken = localStorage.getItem('loginId') || ''
	return reqBody;
}
export function createRequestObjWithoutStoreId(reqObj) {
	const reqBody = { ...reqObj };

	reqBody.deviceFingerprint = {
		// "createdAt": 0,

		jsonOtherInfo: localStorage.getItem('jsonOtherInfo') || '',
		// "user_id": 0,
		deviceFingerprint: fingerPrint.toString()
	};
	reqBody.loginToken = localStorage.getItem('loginId') || '';
	return reqBody;
}
